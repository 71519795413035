import React from "react"
import { teamManagementPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const TeamManagement = () => {
  return (
    <Layout>
      <Seo
        title="Optimise Team Management for Accountants with Synkli"
        description={`Use Synkli to organise your team digitally. Assign tasks, track performance, and ensure alignment to boost practice management for accountants.`}
      />

      <FeatureDetailsPage data={teamManagementPage} />
      
    </Layout>
  )
}

export default TeamManagement
